<script>
import TextVersionsComparisonSelect from '@/components/Item/components/TextVersionsComparisonSelect.vue';
import BaseSpinner from '@/components/base/BaseSpinner.vue';
export default {
  name: 'TextVersionsComparison',
  components: {
    TextVersionsComparisonSelect,
    BaseSpinner,
  },
  props: {
    current: {
      type: Object,
      required: true,
    },
    versions: {
      type: Array,
      required: true,
    },
    itemId: {
      type: Number,
      required: true,
    },
    itemKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fetching: false,
      leftText: null,
      leftTextSelect:
        this.versions[0].id === this.current.id
          ? this.versions[1].id
          : this.current.id,
      leftTextDisabled: false,
      rightText: null,
      rightTextSelect: this.versions[0].id,
      rightTextDisabled: false,
      animationText: null,
      offsetMoveFrom: null,
      offsetMoveTo: null,
      moves: [],
      moveHoveredIndex: null,
      isMoveHovered: false,
      handleMovedTextTimeout: null,
      watchers: [],
      hoverTimeout: null,
      isScrolling: false,
      indenticalTexts: false,
      navigationIndex: null,
      navigationSummary: null,
      leftElements: null,
      rightElements: null,
      leftMoveElements: null,
      rightMoveElements: null,
      isSyncingScroll: false,
      isSyncingLeft: false,
      isSyncingRight: false,
      isSyncingScrollDisabled: false,
      syncTimeout: null,
      smoothScrollTimeout: null,
      isSyncingScrollDisabledTimeout: null,
      previousScrollPosition: null,
      isMobile: false,
      isPortrait: false,
      currentLeftScroll: 0,
      currentRightScroll: 0,
      scrollDownTimeout: null,
      scrollingDown: false,
      scrollingDownPreviousPosition: 0,
      leftTextContainerScroll: 0,
      rightTextContainerScroll: 0,
      endIsNear: false,
    };
  },
  computed: {
    leftTextOptions: function() {
      return this.versions.map((item, index, array) => ({
        value: item.id,
        label: item.description,
        data: item,
        reverseIndex: array.length - index,
        disabled: item.id === this.rightTextSelect,
      }));
    },
    rightTextOptions: function() {
      return this.versions.map((item, index, array) => ({
        value: item.id,
        label: item.description,
        data: item,
        reverseIndex: array.length - index,
        disabled: item.id === this.leftTextSelect,
      }));
    },
    showTop: function() {
      return (this.isMobile && !this.scrollingDown) || !this.isMobile;
    },
  },
  watch: {
    navigationIndex: function(v) {
      if (v) {
        this.scrollToElement(v);
      }
    },
  },
  created() {
    this.getTexts();
    // document.addEventListener('click', this.handleMovedText);
    document.addEventListener('mouseenter', this.handleHover, true);
    document.addEventListener('mouseleave', this.handleHover, true);
    document.addEventListener('click', this.dynamicNavClickListener, true);
  },
  mounted() {
    this.checkDevice();
    this.checkHeights();
    window.addEventListener('resize', this.checkDevice);
    window.addEventListener('resize', this.checkHeights);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDevice);
    window.removeEventListener('resize', this.checkHeights);
  },
  destroyed() {
    // document.removeEventListener('click', this.handleMovedText);
    document.removeEventListener('mouseenter', this.handleHover);
    document.removeEventListener('mouseleave', this.handleHover);
    this.$refs.leftTextContainer.removeEventListener(
      'scroll',
      this.handleMovedText
    );
    this.$refs.rightTextContainer.removeEventListener(
      'scroll',
      this.handleMovedText
    );
    document.removeEventListener('click', this.dynamicNavClickListener);
    this.removeSynchScrollListeners();
  },
  methods: {
    checkHeights() {
      if (this.$refs.leftTextContainer) {
        this.leftTextContainerScroll = 0;
        setTimeout(() => {
          this.leftTextContainerScroll = this.$refs.leftTextContainer.scrollHeight;
        }, 20);
      }
      if (this.$refs.rightTextContainer) {
        this.rightTextContainerScroll = 0;
        setTimeout(() => {
          this.rightTextContainerScroll = this.$refs.rightTextContainer.scrollHeight;
        }, 20);
      }
    },
    checkDevice() {
      /* eslint-disable */
      const userAgentCheck =
        process.env.NODE_ENV === 'production'
          ? /android|iphone|ipad|ipod|blackberry|iemobile|opera mini/.test(
              navigator.userAgent.toLowerCase()
            )
          : false;
      /* eslint-enable */
      this.isMobile = userAgentCheck || window.innerWidth <= 932;
      this.isPortrait = window.innerHeight > window.innerWidth;
    },
    dynamicNavClickListener(event) {
      const navElement = event.target.closest('[data-nav-identifier]');
      if (navElement) {
        const identifierValue = navElement.getAttribute('data-nav-identifier');
        console.log(
          'Kliknięto element o data-nav-identifier:',
          identifierValue
        );
        this.navigationIndex = identifierValue;
      }
    },
    createSynchScrollListeners() {
      this.createLeftScrollListener();
      this.createRightScrollListener();
    },
    createLeftScrollListener() {
      if (this.$refs.leftTextContainer) {
        this.$refs.leftTextContainer.addEventListener(
          'scroll',
          this.syncScroll,
          {
            passive: true,
          }
        );
      }
    },
    createRightScrollListener() {
      if (this.$refs.rightTextContainer) {
        this.$refs.rightTextContainer.addEventListener(
          'scroll',
          this.syncScroll,
          {
            passive: true,
          }
        );
      }
    },
    removeSynchScrollListeners() {
      if (this.$refs.leftTextContainer) {
        this.$refs.leftTextContainer.removeEventListener(
          'scroll',
          this.syncScroll
        );
      }
      if (this.$refs.rightTextContainer) {
        this.$refs.rightTextContainer.removeEventListener(
          'scroll',
          this.syncScroll
        );
      }
    },
    smoothScrollTo(element, targetScrollTop, callback) {
      const start = element.scrollTop;
      const distance = Math.abs(targetScrollTop - start);
      const estimatedDuration = Math.min(150 + distance * 0.5, 1000);

      element.scrollTo({
        top: targetScrollTop,
        behavior: 'smooth',
      });

      setTimeout(() => {
        if (callback) callback();
      }, estimatedDuration);
    },
    createWatchers() {
      this.removeWatchers();
      ['leftTextSelect', 'rightTextSelect'].forEach(key => {
        const unwatch = this.$watch(key, () => {
          this.getTexts();
        });
        this.watchers.push({ key, unwatch });
      });
    },
    removeWatchers() {
      this.watchers.forEach(watcher => {
        watcher.unwatch();
      });
      this.watchers = [];
    },
    extractNumberFromId(id) {
      const match = id ? id.match(/\d+/) : null;
      return match ? match[0] : null;
    },
    handleMouseLeave(identifier) {
      if (identifier) {
        document
          .querySelectorAll(`[data-identifier="${identifier}"]`)
          .forEach(el => el.classList.remove('hovered'));
      }
    },
    handleHover(event) {
      /* eslint-disable */
      const element = event.target;
      const elementId = element.id;
      const isInComparisonDiv =
        element &&
        element.closest &&
        element.closest('#text-version-comparison-left')
          ? 'left'
          : element &&
            element.closest &&
            element.closest('#text-version-comparison-right')
          ? 'right'
          : null;
      /* eslint-enable */

      const identifier =
        element &&
        element.getAttribute &&
        element.getAttribute('data-identifier');

      if (identifier && !this.indenticalTexts) {
        document
          .querySelectorAll(`[data-identifier="${identifier}"]`)
          .forEach(el => el.classList.add('hovered'));
      }
      element.addEventListener(
        'mouseleave',
        () => this.handleMouseLeave(identifier),
        { once: true }
      );

      if (
        isInComparisonDiv &&
        elementId &&
        (elementId.startsWith('move-from-') || elementId.startsWith('move-to-'))
      ) {
        const extractedNumber = this.extractNumberFromId(elementId);

        if (extractedNumber) {
          const counterpartId = elementId.startsWith('move-from-')
            ? `move-to-${extractedNumber}`
            : `move-from-${extractedNumber}`;
          const counterpartElement = document.getElementById(counterpartId);
          this.removeSynchScrollListeners();

          if (event.type === 'mouseenter') {
            if (counterpartElement) {
              counterpartElement.classList.add('hover');
            }

            this.hoverTimeout = setTimeout(() => {
              this.moveHoveredIndex = extractedNumber;
              this.isMoveHovered = true;
              const oppositeDivId =
                isInComparisonDiv === 'left'
                  ? '#text-version-comparison-right'
                  : '#text-version-comparison-left';
              const oppositeDiv = document.querySelector(oppositeDivId);

              if (oppositeDiv) {
                const oppositeElementId = elementId.startsWith('move-to-')
                  ? `move-from-${extractedNumber}`
                  : `move-to-${extractedNumber}`;
                const oppositeElement = oppositeDiv.querySelector(
                  `#${oppositeElementId}`
                );

                if (oppositeElement) {
                  this.previousScrollPosition = this.previousScrollPosition
                    ? this.previousScrollPosition
                    : oppositeDiv.scrollTop;

                  const elementTop = element.getBoundingClientRect().top;
                  const oppositeElementTop = oppositeElement.getBoundingClientRect()
                    .top;
                  const currentDivTop = element
                    .closest(
                      isInComparisonDiv === 'left'
                        ? '#text-version-comparison-left'
                        : '#text-version-comparison-right'
                    )
                    .getBoundingClientRect().top;
                  const oppositeDivTop = oppositeDiv.getBoundingClientRect()
                    .top;

                  const offset = elementTop - currentDivTop;
                  const scrollOffset = oppositeElementTop - oppositeDivTop;
                  const finalScroll = scrollOffset - offset;

                  oppositeDiv.scrollTo({
                    top: oppositeDiv.scrollTop + finalScroll,
                    behavior: 'smooth',
                  });
                }
              }
            }, 200);
          } else if (event.type === 'mouseleave') {
            this.isMoveHovered = false;
            clearTimeout(this.hoverTimeout);
            if (counterpartElement) {
              counterpartElement.classList.remove('hover');
            }

            const oppositeDivId =
              isInComparisonDiv === 'left'
                ? '#text-version-comparison-right'
                : '#text-version-comparison-left';
            const oppositeDiv = document.querySelector(oppositeDivId);

            const currentmoveHoveredIndex = this.moveHoveredIndex;

            setTimeout(() => {
              if (oppositeDiv && !this.isMoveHovered) {
                if (isInComparisonDiv === 'right') {
                  this.createRightScrollListener();
                } else {
                  this.createLeftScrollListener();
                }
                this.smoothScrollTo(
                  oppositeDiv,
                  this.previousScrollPosition
                    ? this.previousScrollPosition
                    : oppositeDiv.scrollTop,
                  () => {
                    if (this.smoothScrollTimeout) {
                      clearTimeout(this.smoothScrollTimeout);
                    }
                    if (
                      currentmoveHoveredIndex !== this.moveHoveredIndex ||
                      (!currentmoveHoveredIndex && !this.moveHoveredIndex)
                    ) {
                      return;
                    }

                    this.smoothScrollTimeout = setTimeout(() => {
                      this.previousScrollPosition = null;
                      this.moveHoveredIndex = null;
                      if (this.isSyncingScrollDisabledTimeout) {
                        clearTimeout(this.isSyncingScrollDisabledTimeout);
                      }
                      this.isSyncingScrollDisabledTimeout = setTimeout(() => {
                        if (isInComparisonDiv === 'left') {
                          this.createRightScrollListener();
                        } else {
                          this.createLeftScrollListener();
                        }
                      }, 50);
                    }, 50);
                  }
                );
              } else {
                this.moveHoveredIndex = null;
              }
            }, 100);
          }
        }
      }
    },
    handleMovedText(event) {
      if (this.handleMovedTextTimeout) {
        clearTimeout(this.handleMovedTextTimeout);
      }

      this.handleMovedTextTimeout = setTimeout(() => {
        const elements = document.querySelectorAll('[id^="move-"]');
        const movesInfo = new Map();

        elements.forEach(element => {
          const id = element.id;
          const match = id.match(/move-(to|from)-(\d+)/);
          if (match) {
            const moveType = match[1];
            const moveNumber = match[2];

            const parent = element.parentElement;
            let offset = null;
            let side = null;

            // if (parent) {
            const parentHeight = parent.offsetHeight;
            const visibleTop = parent.scrollTop;
            const elementTop = element.offsetTop;
            offset =
              ((elementTop - visibleTop + element.offsetHeight / 2) /
                parentHeight) *
              100;
            // }

            const sideContainer = element.closest(
              '#text-version-comparison-left, #text-version-comparison-right'
            );
            if (sideContainer) {
              side =
                sideContainer.id === 'text-version-comparison-left'
                  ? 'left'
                  : 'right';
            }

            if (!movesInfo.has(moveNumber)) {
              movesInfo.set(moveNumber, {
                moveNumber,
                offsetTo: null,
                offsetFrom: null,
                sideTo: null,
                sideFrom: null,
                offsetDistanceY: null,
                visible: false,
              });
            }

            const moveData = movesInfo.get(moveNumber);
            if (moveType === 'to') {
              moveData.offsetTo = offset;
              moveData.sideTo = side;
            } else if (moveType === 'from') {
              moveData.offsetFrom = offset;
              moveData.sideFrom = side;
            }

            if (moveData.offsetTo !== null && moveData.offsetFrom !== null) {
              moveData.offsetDistanceY = Math.abs(
                moveData.offsetTo - moveData.offsetFrom
              );
              moveData.visible =
                (moveData.offsetTo <= 100 && moveData.offsetFrom >= 0) ||
                (moveData.offsetFrom <= 100 && moveData.offsetTo >= 0);
            }
          }
        });

        let moves = Array.from(movesInfo.values());

        let endAndEndVisibilityChecked = false;

        this.moves = moves.map((item, index, array) => {
          const siblings = array
            .filter(
              other =>
                other !== item &&
                Math.max(item.offsetFrom, other.offsetFrom) <=
                  Math.min(item.offsetTo, other.offsetTo)
            )
            .map(other => other.moveNumber);

          if (
            item.visible &&
            ((item.offsetFrom < 0 && item.offsetTo > 100) ||
              (item.offsetFrom > 100 && item.offsetTo < 0))
          ) {
            if (endAndEndVisibilityChecked) {
              item.visible = false;
            }
            endAndEndVisibilityChecked = true;
          }

          return { ...item, siblings };
        });
        // this.syncScroll(event);
      }, 5);
    },
    syncScroll(event) {
      if (
        this.isSyncingScroll ||
        !event ||
        this.moveHoveredIndex ||
        this.isSyncingScrollDisabled
      ) {
        return;
      }

      const scrolledContainer = event.target;

      if (this.scrollDownTimeout) {
        clearTimeout(this.scrollDownTimeout);
      }

      this.scrollDownTimeout = setTimeout(() => {
        const currentScrollTop = scrolledContainer.scrollTop;
        if (
          currentScrollTop > this.scrollingDownPreviousPosition &&
          currentScrollTop > 50
        ) {
          this.scrollingDown = true;
        } else if (
          currentScrollTop < this.scrollingDownPreviousPosition ||
          currentScrollTop < 50
        ) {
          this.scrollingDown = false;
        }
        this.scrollingDownPreviousPosition = currentScrollTop;
      }, 300);

      const leftContainer = document.getElementById(
        'text-version-comparison-left'
      );
      const scrollTop = leftContainer.scrollTop;
      const scrollHeight = leftContainer.scrollHeight;
      const clientHeight = leftContainer.clientHeight;

      const distanceToBottom = scrollHeight - scrollTop - clientHeight;

      this.endIsNear = distanceToBottom < 400;

      const rightContainer = document.getElementById(
        'text-version-comparison-right'
      );

      if (!leftContainer || !rightContainer) {
        return;
      }

      this.currentRightScroll = rightContainer.scrollTop;
      this.currentLeftScroll = leftContainer.scrollTop;

      if (scrolledContainer.id === 'text-version-comparison-left') {
        if (this.isSyncingRight) return;
        this.isSyncingLeft = true;
      } else {
        if (this.isSyncingLeft) return;
        this.isSyncingRight = true;
      }

      const otherContainer =
        scrolledContainer.id === 'text-version-comparison-left'
          ? rightContainer
          : leftContainer;

      const spans =
        scrolledContainer.id === 'text-version-comparison-left'
          ? this.leftElements
          : this.rightElements;
      for (const span of spans) {
        const rect = span.getBoundingClientRect();
        const containerRect = scrolledContainer.getBoundingClientRect();
        if (containerRect.top >= rect.top && containerRect.top <= rect.bottom) {
          const identifier = span.getAttribute('data-identifier');
          const correspondingSpan = otherContainer.querySelector(
            `span[data-identifier="${identifier}"]`
          );

          if (correspondingSpan) {
            const correspondingRect = correspondingSpan.getBoundingClientRect();
            const otherContainerRect = otherContainer.getBoundingClientRect();
            otherContainer.scrollTop +=
              correspondingRect.top -
              otherContainerRect.top -
              (rect.top - containerRect.top);
          }
          break;
        }
      }

      if (this.syncTimeout) {
        clearTimeout(this.syncTimeout);
      }

      this.syncTimeout = setTimeout(() => {
        if (scrolledContainer.id === 'text-version-comparison-left') {
          this.isSyncingLeft = false;
        } else {
          this.isSyncingRight = false;
        }
      }, 50);
    },
    mergeAdjacentSpans(html) {
      const d = document.createElement('div');
      d.innerHTML = html;
      (function traverse(n) {
        for (let c = n.firstChild; c; c = c.nextSibling) {
          if (c.nodeType === 1) {
            if (c.matches('span.added') || c.matches('span.deleted')) {
              const cls = c.classList.contains('added') ? 'added' : 'deleted';
              while (
                c.nextElementSibling &&
                c.nextElementSibling.matches(`span.${cls}`)
              ) {
                c.innerHTML += c.nextElementSibling.innerHTML;
                c.nextElementSibling.remove();
              }
            }
            traverse(c);
          }
        }
      })(d);
      return d.innerHTML;
    },
    async getTexts() {
      this.removeWatchers();
      try {
        this.fetching = true;
        const response = await this.$api.get(
          `v0/${this.$store.state.list.api.list}/compare/${this.itemKey}/${
            this.itemId
          }/${this.versions.findIndex(
            item => item.id === this.leftTextSelect
          )}/${this.versions.findIndex(
            item => item.id === this.rightTextSelect
          )}`
        );
        this.offsetMoveFrom = null;
        this.offsetMoveTo = null;

        this.leftText = this.mergeAdjacentSpans(
          response.data.old_version.replaceAll('\n', '<br>')
        );
        this.rightText = this.mergeAdjacentSpans(
          response.data.new_version.replaceAll('\n', '<br>')
        );

        const countElements = (html, className, ignoreNextSiblings) => {
          const tempDiv = document.createElement('div');
          tempDiv.innerHTML = html;
          return tempDiv.getElementsByClassName(className).length;
        };

        const leftAddedCount = countElements(this.leftText, 'added', true);
        const leftDeletedCount = countElements(this.leftText, 'deleted', true);
        const leftMoveToCount = countElements(this.leftText, 'move-to-block');

        const rightAddedCount = countElements(this.rightText, 'added', true);
        const rightDeletedCount = countElements(
          this.rightText,
          'deleted',
          true
        );
        const rightMoveToCount = countElements(this.rightText, 'move-to-block');

        const totalAdded = leftAddedCount + rightAddedCount;
        const totalDeleted = leftDeletedCount + rightDeletedCount;
        const totalMoveTo = leftMoveToCount + rightMoveToCount;

        this.navigationSummary = totalAdded + totalDeleted + totalMoveTo;
        this.navigationIndex = this.navigationSummary > 0 ? 1 : null;

        // Poczekaj na zakończenie cyklu renderowania
        this.$nextTick(() => {
          this.leftMoveElements = Array.from(
            document.querySelectorAll(
              '#text-version-comparison-left .added, #text-version-comparison-left .deleted, #text-version-comparison-left .move-to-block'
            )
          );
          this.rightMoveElements = Array.from(
            document.querySelectorAll(
              '#text-version-comparison-right .added, #text-version-comparison-right .deleted, #text-version-comparison-right .move-to-block'
            )
          );

          this.leftElements = Array.from(
            document.querySelectorAll(
              '#text-version-comparison-left span[data-identifier]'
            )
          );
          this.rightElements = Array.from(
            document.querySelectorAll(
              '#text-version-comparison-right span[data-identifier]'
            )
          );
          const allElements = [
            ...this.leftMoveElements,
            ...this.rightMoveElements,
          ].sort(
            (a, b) =>
              a.getBoundingClientRect().top - b.getBoundingClientRect().top
          );

          allElements.forEach((element, index) => {
            element.setAttribute('data-nav-identifier', index + 1);
          });

          this.checkHeights();
        });

        this.leftTextSelect = this.versions[response.data.old_index].id;
        this.rightTextSelect = this.versions[response.data.new_index].id;
        this.indenticalTexts = response.data.identical;
        this.handleMovedText();
      } catch (e) {
        console.error(e);
      } finally {
        this.createWatchers();
        this.$nextTick(() => {
          this.createSynchScrollListeners();
        });
        this.fetching = false;
      }
    },
    scrollToElement(index) {
      const targetElement = document.querySelector(
        `[data-nav-identifier="${index}"]`
      );
      if (!targetElement) {
        return;
      }

      [...this.leftMoveElements, ...this.rightMoveElements].forEach(el =>
        el.classList.remove('active')
      );
      targetElement.classList.add('active');

      const scrollContainer = targetElement.closest(
        '.text-version-comparison--text'
      );

      scrollContainer.scrollTo({
        top: targetElement.offsetTop - scrollContainer.offsetTop,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<template>
  <div style="flex-grow: 1; display: flex; flex-direction: column">
    <portal :to="isMobile ? 'buttons-sidebar' : 'comparison-navigation'">
      <div
        v-if="isMobile && !isPortrait"
        :class="[
          'modal__close-sticky--compare',
          {
            'modal__close-sticky--compareSlided': !showTop,
          },
        ]"
        @click="$emit('compare', false)"
      >
        <svg
          data-v-07ed370a=""
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            data-v-07ed370a=""
            d="M18 6L6 18"
            stroke="currentColor"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            data-v-07ed370a=""
            d="M6 6L18 18"
            stroke="currentColor"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
        </svg>
      </div>
      <transition name="slide-left">
        <div
          v-if="navigationIndex && ((isMobile && !isPortrait) || !isMobile)"
          class="d-flex mb-2"
        >
          <div
            :class="[
              'text-version-comparison-navigation',
              {
                'text-version-comparison-navigation--mobile': isMobile,
                'text-version-comparison-navigation--hidden':
                  isMobile && endIsNear,
              },
            ]"
          >
            <button @click="navigationIndex > 1 ? navigationIndex-- : null">
              <img :src="require('@/assets/icons/ArrowUpNav.svg')" />
            </button>
            <div>{{ navigationIndex }}/{{ navigationSummary }}</div>
            <button
              @click="
                navigationIndex < navigationSummary ? navigationIndex++ : null
              "
            >
              <img :src="require('@/assets/icons/ArrowDownNav.svg')" />
            </button>
          </div>
        </div>
      </transition>
    </portal>
    <div
      :class="[
        'text-version-comparison',
        {
          'text-version-comparison--mobile': isMobile,
        },
      ]"
    >
      <div
        :class="[
          'text-version-comparison__rotate',
          {
            'text-version-comparison__rotate--active': isMobile && isPortrait,
          },
        ]"
      >
        <img :src="require('@/assets/icons/RotateDevice.svg')" />
        Obróć urządzenie aby porównać wersje dokumentów.
      </div>
      <div class="text-version-comparison__grid">
        <BaseWrapperInjectElement>
          <Transition name="fade">
            <text-versions-comparison-select
              v-if="showTop"
              v-model="leftTextSelect"
              :options="leftTextOptions"
              @toggled="v => (leftTextDisabled = v)"
            />
          </Transition>
        </BaseWrapperInjectElement>
        <div class="text-version-comparison__arrow">
          <BaseWrapperInjectElement>
            <Transition name="fade">
              <img
                v-if="showTop"
                src="@/assets/icons/ArrowRightAlt.svg"
                alt=""
              />
            </Transition>
          </BaseWrapperInjectElement>
        </div>
        <div>
          <BaseWrapperInjectElement>
            <Transition name="fade">
              <text-versions-comparison-select
                v-if="showTop"
                v-model="rightTextSelect"
                :options="rightTextOptions"
                @toggled="v => (rightTextDisabled = v)"
              />
            </Transition>
          </BaseWrapperInjectElement>
        </div>
        <div v-if="indenticalTexts" class="text-version-comparison__identical">
          <div>Porównywane wersje są identyczne</div>
        </div>
        <div v-if="fetching" class="text-version-comparison__spinner">
          <BaseSpinner />
        </div>
        <template v-else>
          <div
            id="text-version-comparison-left"
            :class="[
              'text-version-comparison--text',
              {
                'text-version-comparison--textMobile': isMobile,
              },
            ]"
            ref="leftTextContainer"
            :style="
              `--height: ${
                leftTextContainerScroll ? `${leftTextContainerScroll}px` : null
              };`
            "
            v-html="leftText"
            @scroll="handleMovedText"
          />
          <div
            class="text-version-comparison__line"
            :style="
              `--items-count: ${moves.filter(item => item.visible).length};`
            "
          >
            <div
              v-for="(move, i) in moves"
              :key="`move-${i}`"
              :style="
                `--offset-top: ${
                  move.offsetTo > move.offsetFrom
                    ? move.offsetFrom
                    : move.offsetTo
                }%; --offset-bottom: ${
                  move.offsetTo <= move.offsetFrom
                    ? move.offsetFrom
                    : move.offsetTo
                }%;     --items-index: ${moves
                  .filter(item => item.visible)
                  .indexOf(move)}; opacity: ${move.visible ? 1 : 0}; ${
                  move.offsetDistanceY < 15 && move.offsetDistanceY > -15
                    ? `--height-divs: ${move.offsetDistanceY * 2 + 2}px`
                    : ''
                }`
              "
              @mouseover="moveHoveredIndex = move.moveNumber"
              @mouseleave="moveHoveredIndex = null"
              :class="[
                `text-version-comparison__lineItem text-version-comparison__lineItem--${
                  move.offsetTo > move.offsetFrom ? move.sideFrom : move.sideTo
                }`,
                {
                  'text-version-comparison__lineItem--close':
                    move.offsetDistanceY < 15 && move.offsetDistanceY > -15,
                  'text-version-comparison__lineItem--hide':
                    moveHoveredIndex && moveHoveredIndex !== move.moveNumber,
                  'text-version-comparison__lineItem--hover':
                    moveHoveredIndex && moveHoveredIndex === move.moveNumber,
                },
              ]"
            >
              <div
                :style="
                  move.offsetDistanceY < 15 && move.offsetDistanceY > -15
                    ? `height: ${move.offsetDistanceY * 2 + 2}px`
                    : ''
                "
              />
              <div
                :style="
                  move.offsetDistanceY < 15 && move.offsetDistanceY > -15
                    ? `height: ${move.offsetDistanceY * 2 + 2}px`
                    : ''
                "
              />
            </div>
          </div>
          <div
            id="text-version-comparison-right"
            :class="[
              'text-version-comparison--text',
              {
                'text-version-comparison--textMobile': isMobile,
              },
            ]"
            ref="rightTextContainer"
            :style="
              `--height: ${
                rightTextContainerScroll
                  ? `${rightTextContainerScroll}px`
                  : null
              }; ${indenticalTexts ? `opacity: .4` : ''}`
            "
            v-html="rightText"
            @scroll="handleMovedText"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.text-version-comparison {
  border-radius: 10px;
  border: 1px solid #e5e5ea;
  background-color: $gray-6;
  &:not(&--mobile) {
    padding: 1rem 2rem;
    position: unset;
    height: auto;
    width: auto;
    overflow: auto;
  }
  &--mobile {
    position: fixed;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    top: 0;
    left: 0;
    padding: 1rem 1rem;
    .text-version-comparison-navigation {
    }
  }
  flex-grow: 1;
  &__rotate {
    position: fixed;
    z-index: -100000;
    opacity: 0;
    height: 100vh;
    width: 100vw;
    background-color: white;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-inline: 20px;
    text-align: center;
    transition: opacity 200ms ease-in-out 100ms, z-index 200ms ease-in-out 100ms;
    > img {
      width: 180px;
    }
    &--active {
      opacity: 1;
      z-index: 10000000;
    }
  }
  &-navigation {
    flex-direction: row;
    position: unset;
    box-shadow: none;
    background-color: #f2f2f2;
    min-height: 70px;
    border-radius: 8px;
    padding-inline: 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 8px;
    justify-content: center;
    z-index: 1;
    bottom: 0;
    right: -10px;
    &--mobile {
      flex-direction: column;
      position: absolute;
      box-shadow: 0px 1.43px 15.74px rgba(0, 0, 0, 0.25);
      bottom: 10px;
      transition: right 400ms ease-in-out;
    }
    &--hidden {
      right: -100px;
    }
    > div {
      font-size: 0.9rem;
      background-color: white;
      min-height: 26px;
      padding: 2px 8px;
      border-radius: 4px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
  }
  &__grid {
    display: grid;
    overflow: hidden;
    @include mq('tablet') {
      grid-template-columns: 1fr 20px 1fr;
      gap: 10px;
    }
    grid-template-columns: 1fr 15px 1fr;
    gap: 10px 5px;
    > div:nth-child(-n + 3) {
      background-color: $gray-6;
      position: relative;
      z-index: 10000;
    }
  }
  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100px;
      background: linear-gradient(
        to bottom,
        rgba($gray-6, 1) 0%,
        rgba($gray-6, 0) 50%
      ); // Punkt przejścia
      top: 100%;
      left: 0;
      z-index: 100;
    }
  }
  &__spinner {
    grid-column: span 3;
    display: flex;
    justify-content: center;
    padding: 25px 0;
  }
  &__identical {
    grid-column: span 3;
    color: $gray-3;
    padding-left: calc(50% + 20px);
    text-align: center;
    font-size: 12px;
  }
  &__line {
    position: relative;
    height: 100%;
    &Item {
      transition: opacity 100ms ease-in-out;
      > div {
        transition: border-color 100ms ease-in-out;
        border-color: #ffe98a !important;
      }
      &--hide {
        opacity: 0.5;
      }
      &--hover {
        &::before {
          background-color: #ffd000 !important;
        }
        > div {
          border-color: #ffd000 !important;
          //border-right: 0 !important;
          //border-left: 0 !important;
          //border-radius: 0 !important;
        }
      }
      &--close {
        &::before {
          height: calc(100% - var(--height-divs)) !important;
        }
      }
      &--equal {
        > div {
          &:first-child {
            top: 0;
            border-right: 0 !important;
            border-top: 2px solid;
            border-top-right-radius: 0 !important;
            right: 0;
          }
          &:last-child {
            bottom: 0;
            border-left: 0 !important;
            border-bottom: 2px solid;
            border-bottom-left-radius: 0 !important;
            left: 0;
          }
        }
      }
    }
    &Item--left {
      > div {
        &:first-child {
          top: 0;
          border-right: 2px solid;
          border-top: 2px solid;
          border-top-right-radius: 10px;
          right: 0;
        }
        &:last-child {
          bottom: 0;
          border-left: 2px solid;
          border-bottom: 2px solid;
          border-bottom-left-radius: 10px;
          left: 0;
        }
      }
    }
    &Item--right {
      > div {
        &:first-child {
          top: 0;
          border-left: 2px solid;
          border-top: 2px solid;
          border-top-left-radius: 10px;
          left: 0;
        }
        &:last-child {
          bottom: 0;
          border-right: 2px solid;
          border-bottom: 2px solid;
          border-bottom-right-radius: 10px;
          right: 0;
        }
      }
    }
    z-index: 10;
    > div {
      position: absolute;
      width: 1.5px;
      left: calc(
        (100% / var(--items-count)) * var(--items-index) +
          (50% / var(--items-count))
      );
      margin-inline: auto;
      transition: left 200ms ease-in-out, opacity 1000ms ease-in-out;
      top: var(--offset-top);
      bottom: calc(100% - var(--offset-bottom));
      &::before {
        transition: background-color 100ms ease-in-out;
        background-color: #ffe98a;
        height: calc(100% - 30px);
        top: 50%;
        transform: translateY(-50%);
        content: '';
        width: 100%;
        position: absolute;
      }
      > div {
        height: 15px;
        // width: calc(30px - ((var(--items-count) - 1) * 5px));
        width: 100px;
        position: absolute;
      }
    }
  }
  &--text {
    background-color: #fff;
    border-radius: 10px;
    padding: 1.2rem 1rem;
    position: relative;
    //height: 700px;
    height: calc(100dvh - 300px);
    overflow-y: auto;
    text-align: justify;
    scroll-behavior: unset;
    line-height: 20px;
    &Mobile {
      height: calc(100dvh - 20px);
      //&WithMenu {
      //  height: calc(700px - 48px);
      //}
    }
    > span {
      position: relative;
      z-index: 1000;
    }
    &::before {
      position: absolute;
      width: calc(100% - 20px);
      height: var(--height);
      top: 0;
      left: 10px;
      background-color: white;
      content: '';
      z-index: 100;
    }
    [data-identifier] {
      background-color: white;
      transition: background-color 100ms ease-in-out;
      &:hover,
      &.hovered {
        background-color: $gray-5;
      }
    }
    .deleted {
      //background-color: #f8b0bd;
      //&.active {
      //  background-color: #ef5470;
      //}
      background-color: #fde6ea;
      color: #d20026;
      cursor: pointer;
      &.active {
        background-color: #f48a9d;
      }
    }
    .added {
      //background-color: #b4f6d6;
      //&.active {
      //  background-color: #5ceca7;
      //}
      cursor: pointer;
      background-color: #b4f6d6;
      &.active {
        background-color: #5ceca7;
      }
    }
    .move-from-block,
    .move-to-block {
      display: inline !important;
      cursor: pointer;
      // background-color: #b0c4ff;
      background-color: #ffe98a;
      transition: background-color 100ms ease-in-out;
      &:hover,
      &.hover,
      &.active {
        ///background-color: #8aa8ff;
        background-color: #ffd000;
      }
    }
  }
}
.modal__close-sticky--compare {
  background-color: white;
  color: $primary;
  box-shadow: 0px 1.43px 15.74px rgba(0, 0, 0, 0.25);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
  z-index: 1;
  right: -5px;
  top: 40px;
  position: absolute;
  transition: top 200ms ease-in-out;
  &Slided {
    top: 0px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
